<template>
  <b-container>
    <b-row class="mb-2">
      <b-col cols="12">
        <h2>Les pratiques de vos exploitants</h2>
      </b-col>
    </b-row>
    <b-row v-if="practices">
      <b-col cols="6">
        <b-row
          v-for="practice in practices"
          :key="practice.name"
        >
          <b-col>
            <PracticeCard
              :practice="practice"
              :is-active="currentPracticeName === practice.indicator_name"
              @click.native="isClickable(practice)"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <div style="position: sticky; top: 95px;">
          <DistributionChart
            v-if="currentPractice"
            :indicator="currentPractice"
            style="height: 435px;"
          />
          <IaaFilter
            row
            @initCurrentPractice="initCurrentPractice"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import PracticeCard from "@/components/iaa/PracticeCard.vue";
import DistributionChart from "@/components/iaa/DistributionChart.vue";
import IaaFilter from "@/components/iaa/IaaFilter.vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    PracticeCard,
    DistributionChart,
    IaaFilter
  },
  data() {
    return {
      currentPractice: {}
    };
  },
  computed: {
    ...mapGetters("iaa", ["practices", "cooperatives"]),
    currentPracticeName() {
      return this.currentPractice?.indicator_name;
    }
  },
  watch: {
    practices() {
      this.initCurrentPractice();
    }
  },
  created() {
    this.initCurrentPractice();
  },
  methods: {
    initCurrentPractice() {
      for (let i = 0; i < this.practices.length; i += 1) {
        if (this.practices[i].measures && this.practices[i].measures.length > 0) {
          this.currentPractice = this.practices[i];
          break;
        } else {
          this.currentPractice = null;
        }
      }
    },
    isClickable(practice) {
      if (practice.measures && practice.measures.length > 0) {
        this.currentPractice = practice;
      }
    }
  }
};
</script>

<template>
  <b-card
    :class="{active: isActive}"
    :style="cursor"
  >
    <b-row align-v="center">
      <b-col cols="8">
        <b-row>
          <b-col class="text-secondary">
            <h3 class="h4">
              {{ practice.indicator_name }}
            </h3>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="4"
        class="h3 text-right"
        style="max-width: 140px;"
      >
        {{ value }}
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    practice: {
      type: Object,
      default: () => ({})
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value() {
      if (this.practice.measure === 0 || this.practice.measure) {
        return this.practice.measure + this.practice.indicator_unit;
      }

      if (!this.practice.measures || !this.practice.measures.length > 0) {
        return "N.C.";
      }

      if (this.practice.indicator_name === "Nombre de ruches") {
        return this.practice.measures.reduce((sum, current) => sum + current, 0);
      }

      const average = (this.practice.measures.reduce((sum, current) => sum + current, 0)) / this.practice.measures.length;
      const prettyAverage = average.toFixed(1) + this.practice.indicator_unit;
      return prettyAverage;
    },
    cursor() {
      if (this.practice.measure === 0 || this.practice.measure) {
        return { cursor: "not-allowed", "box-shadow": "none" };
      }

      if (!this.practice.measures || !this.practice.measures.length > 0) {
        return { cursor: "not-allowed", "box-shadow": "none" };
      }

      return { cursor: "pointer" };
    }
  }
};
</script>

<style lang="scss" scoped>
  .active {
    box-shadow: 0 0 3px 2px #28dac6;
  }
</style>
